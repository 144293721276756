import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageLoaderService {
  visible: Subject<boolean>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  timeout: any;
  constructor() {
    this.visible = new BehaviorSubject<boolean>(false);
  }

  displayPageLoader() {
    // cleaning timeout whenever the page loader is called
    this.timeout = null;
    this.visible.next(true);
    // making sure the default loader is not visible for more than 1.5 sec.
    this.timeout = setTimeout(() => {
      this.visible.next(false);
    }, 1500);
  }
  hidePageLoader() {
    this.visible.next(false);
  }
}
